import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div className="hero">
        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-md-5">
              <div className="hero-content">
                <h1 className="hero-title">
                  Leimai Store
                </h1>
                <p>
                  Create your own store online for free and reach out to many customers with Leimai
                </p>
                <div className="hero-btn mt-5">
                    <Link to='/shop' className="btn custom-btn btn-warning mr-4">Explore</Link>
                    <Link to='/signin' className="btn custom-btn btn-outline-info">Signin</Link>
                </div>
              </div>
            </div>
            <div className="col-md-7">
                <div className="graphic">
                    <img className='hero-img' src={ process.env.PUBLIC_URL + "/imgs/leimai-landing.jpg"} alt="" />
                </div>
            </div>
          </div>
        </div>
    </div>
    )
}

export default Hero;

