import React from "react";
import { Link } from "react-router-dom";

const Default = () => {
  return (
    <div className="card">
      <div className="row no-gutters">
        <div className="col-md-4">
          <img
            src={process.env.PUBLIC_URL + "/imgs/leimai-404.png"}
            class="card-img"
            alt="..."
            style={{ height: "200px", width: "100%" }}
          />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">
              Oops! We can't locate the page you're looking for.
            </h5>
            <Link to="/" className="btn btn-warning">
              Go Home
            </Link>
            <h5 className="card-text">Here's some useful links</h5>
            <p className="card-text">
              Explore selected products in All categories
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Default;
