import React from "react";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <section id="top-bar" className="p-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            Enquiry: &nbsp;
            <i className="fas fa-phone"></i> +232 79 557035
          </div>
          <div className="col-md-4">
            <i className="fas fa-envelope-open"></i> moriehenryk@gmail.com
          </div>
          <div className="col-md-4">
            <div className="social text-right">
              <Link href="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link href="#">
                <i className="fab fa-facebook"></i>
              </Link>
              <Link href="#">
                <i className="fab fa-linkedin"></i>
              </Link>
              <Link href="#">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link href="#">
                <i className="fab fa-pinterest"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Topbar;
