import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCart } from "../cartHelpers";
import CartDetails from "./CartDetails";
import Checkout from "../Checkout";

const Cart = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    document.title = `Leimai Store : You have ${items.length} item(s) in your cart `;
    setItems(getCart());
  }, [items]);

  const showItems = items => {
    return (
      <div className="container-fluid">
        <h2 className="text-center mb-5 mt-3">
          Your cart has {`${items.length}`} item(s)
        </h2>
        <div className="row">
          <div className="col-md-8">
            {items.map((product, i) => (
              <CartDetails
                key={i}
                product={product}
                cartUpdate={true}
                showRemoveProductButton={true}
              />
            ))}
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">
            <h2 className="">Your cart summary</h2>
            <hr />
            <Checkout products={items} />
          </div>
        </div>
      </div>
    );
  };

  const noItemsMessage = () => (
    <div className="container">
      <div className="card mb-3 empty-cart">
        <div className="row no-gutters">
          <div className="col-md-4 col-sm-12 col-xs-12">
            <img
              src={process.env.PUBLIC_URL + "/imgs/leimai-empty-cart.png"}
              className="card-img"
              alt="empty-cart"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">Your shopping cart is empty.</h5>
              <p className="card-text">
                If you already have an account,{" "}
                <Link to="/signin">sign in </Link>
                to see your cart.
              </p>
              <Link to="/shop" className="card-text">
                Continue shopping.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="">
      {items.length > 0 ? showItems(items) : noItemsMessage()}
    </div>
  );
};

export default Cart;
