import React from 'react'

const Footer = () => {

    return (
        <footer id="main-footer" className="py-4 text-white text-center mt-5">
            Copyright &copy; &nbsp;
            <span className="year">2020</span> Leimai Store. All right reserved!
        </footer>
    )
}

export default Footer;