import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { read, listRelated } from "../apiCore";
import ProductDetails from "./ProductDetails";
import Card from "../Card";

const Product = props => {
  const [product, setProduct] = useState({});
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [error, setError] = useState(false);

  // Mapbox of Geolocation
  //   mapboxgl.accessToken =
  //     "pk.eyJ1Ijoid2Vid2lzZXIiLCJhIjoiY2s0eWRpd3c1MDhzaTNsbzQwNmFzZndjbSJ9.ZMGjT73bfTFiOlOuDOjfXg";
  //   const map = new mapboxgl.Map({
  //     container: "map",
  //     style: "mapbox://styles/mapbox/streets-v11"
  //   });

  const loadSingleProduct = productId => {
    read(productId).then(data => {
      if (data.error) {
        setError(data.error);
      } else {
        setProduct(data);
        // fetch related products
        listRelated(data._id).then(data => {
          if (data.error) {
            setError(data.error);
          } else {
            setRelatedProduct(data);
          }
        });
      }
    });
  };

  useEffect(() => {
    const productId = props.match.params.productId;
    loadSingleProduct(productId);
  }, [props]);

  return (
    <Layout
      title={product && product.name}
      description={
        product && product.description && product.description.substring(0, 100)
      }
      className="container-fluid"
    >
      <div className="row product-details">
        <div className="col-lg-12">
          {product && product.description && (
            <ProductDetails key={product._id} product={product} />
          )}
        </div>
      </div>
      <div className="mt-5">
        <h4 className='mt-4 mb-4'>Related products</h4>
        <div className="row">
            {relatedProduct.map((p, i) => (
              <div className="col-3 mb-3">
                <Card key={i} product={p} />
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default Product;
