export const prices = [
    {
        _id: 0,
        name: "Any",
        array: []
    },
    {
        _id: 1,
        name: "SLL 1000 to SLL 9,999",
        array: [1000, 9999]
    },
    {
        _id: 2,
        name: "SLL 10,000 to SLL 99,999",
        array: [10000, 99999]
    },
    {
        _id: 3,
        name: "SLL 100,000 to SLL 999,999",
        array: [100000, 999999]
    },
    {
        _id: 4,
        name: "SLL 1,000,000 to SLL 9,999,999",
        array: [1000000, 9999999]
    },
    {
        _id: 5,
        name: "More than SLL 10,000,0000",
        array: [10000000, 99999999]
    }
];
