import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import ShowImage from "../products/ShowImage";
import moment from "moment";
import { addItem, updateItem, removeItem } from "../cartHelpers";

const CartDetails = ({
  product,
  cartUpdate = false,
  showRemoveProductButton = false
}) => {
  const [redirect, setRedirect] = useState(false);
  const [count, setCount] = useState(product.count);

  const addToCart = () => {
    addItem(product, () => {
      setRedirect(true);
    });
  };

  const shouldRedirect = redirect => {
    if (redirect) {
      return <Redirect to="/cart" />;
    }
  };

  const showRemoveButton = showRemoveProductButton => {
    return (
      showRemoveProductButton && (
        <button
          onClick={() => removeItem(product._id)}
          className="btn btn-outline-danger mt-2 mb-2"
        >
          Remove Product
        </button>
      )
    );
  };

  const showStock = quantity => {
    return quantity > 0 ? (
      <span className="text-danger font-weight-bold">
        Only {product.quantity} left in stock - order now.
      </span>
    ) : (
      <span className="badge badge-warning badge-pill">Out of Stock</span>
    );
  };

  const handleChange = productId => event => {
    setCount(event.target.value < 1 ? 1 : event.target.value);
    if (event.target.value >= 1) {
      updateItem(productId, event.target.value);
    }
  };

  const showCartUpdateOptions = cartUpdate => {
    return (
      cartUpdate && (
        <div className="input-group">
          {/* <div className="input-group-prepend">
              <span className="input-group-text">Adjust Quantity</span>
            </div> */}
          <input
            type="number"
            className="form-control"
            value={count}
            onChange={handleChange(product._id)}
          />
        </div>
      )
    );
  };

  return (
    <div>
      <div className="card cart-detail">
        <div className="row no-gutters">
          <div className="col-md-4">
            <ShowImage item={product} url="product" className="card-img" />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <div className="float-left">
                <h5 className="card-title">{product.name}</h5>
                <h4>SLL {product.price}</h4>
                {showStock(product.quantity)}
              </div>
              <div className="float-right">
                {showRemoveButton(showRemoveProductButton)}

                {showCartUpdateOptions(cartUpdate)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default CartDetails;
