import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import ShowImage from "./ShowImage";
import moment from "moment";
import { addItem } from "../cartHelpers";

const ProductDetails = ({ product, showAddToCartButton = true }) => {
  const [redirect, setRedirect] = useState(false);
  const [count, setCount] = useState(product.count);

  const addToCart = () => {
    addItem(product, () => {
      setRedirect(true);
    });
  };

  const showAddToCart = showAddToCartButton => {
    return (
      //TODO:: disable
      showAddToCartButton && (
        <button
          disabled={product.inCart ? true : false}
          onClick={addToCart}
          className="btn btn-outline-warning mt-2 mb-2"
        >
          Add to cart
        </button>
      )
    );
  };

  const showStock = quantity => {
    return quantity > 0 ? (
      <span className="text-danger font-weight-bold">
        Only {product.quantity} left in stock - order now.
      </span>
    ) : (
      <span className="badge badge-warning badge-pill">Out of Stock</span>
    );
  };

  const showDelivery = shipping =>
    shipping === true ? (
      <p className="text-success font-weight-bold">Free Delivery</p>
    ) : (
      <p className="text-warning font-weight-bold">No Free Delivery!</p>
    );

  const shouldRedirect = redirect => {
    if (redirect) {
      return <Redirect to="/cart" />;
    }
  };

  return (
    <div key={product._id}>
      <div className="row">
        {/* title */}
        <div className="col-10 mx-auto text-center text-slanted">
          <h2 className="text-capitalize">{product.name}</h2>
        </div>
        {/* endtitle */}
      </div>
      <div className="row">
        {shouldRedirect(redirect)}

        <div className="col-10 mx-auto col-md-4 my-3">
          <ShowImage item={product} url="product" className="img-fluid" />
        </div>

        <div className="col-10 mx-auto col-md-4 my-3 text-capitalize">
          <h3>{product.name}</h3>
          <hr />
          <h4 className="text-title text-muted mt-3 mb-2">
            {" "}
            Category: <span>{product.category && product.category.name}</span>
          </h4>
          <h5 className="">
            <strong>
              Price: <span>SLL</span>
              {product.price}
            </strong>
          </h5>
          <p className="black-8">
            Added on {moment(product.createdAt).fromNow()}
          </p>
          {showStock(product.quantity)}
          <hr />
          <p className="text-capitalize font-weight-bold mt-3 mb-0">
            Product description
          </p>
          <p className="text-muted lead">{product.description}</p>
          {/*button */}
          {showDelivery(product.shipping)}
          <div>{showAddToCart(showAddToCartButton)}</div>
        </div>
        <div className="col-10 mx-auto col-md-4 my-3">
          <div
            id="map"
            style={{ width: "100%", height: "500px", borderRadius: "5px" }}
          >
            {product.address}
            <br />
            {product.storeName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
